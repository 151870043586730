import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../components/pageStyles/_Agbs.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Allgemeine Bedingungen" />
    <section className="page">
      <h1 className="text_huge">Allgemeine Bedingungen</h1>
      <strong>für den Verkauf und die Lieferung von Organisations-, Programmierleistungen und Werknutzungsbewilligungen von Softwareprodukten</strong><br /><br />
      <ol>
        <li>
          <strong>Vertragsumfang und Gültigkeit</strong><br />
          <ol>
            <li>
              Alle Aufträge und Vereinbarungen sind nur dann rechtsverbindlich, wenn sie vom Auftragnehmer schriftlich und firmengemäß gezeichnet werden und verpflichten nur in dem in der Auftragsbestätigung angegebenem Umfang. Einkaufsbedingungen des Auftraggebers werden für das gegenständliche Rechtsgeschäft und die gesamte Geschäftsbeziehung hiermit ausgeschlossen. Angebote sind grundsätzlich freibleibend.
            </li>
          </ol>
        </li>
        <li>
          <strong>Leistung und Prüfung</strong><br />
          <ol>
            <li>
              <strong>Gegenstand eines Auftrages kann sein:</strong>
              <ul>
                <li>Ausarbeitung von Organisationskonzepten</li>
                <li>Global- und Detailanalysen</li>
                <li>Erstellung von Individualprogrammen</li>
                <li>Lieferung von Bibliotheks- (Standard-)Programmen</li>
                <li>Erwerb von Nutzungsberechtigungen für Softwareprodukte</li>
                <li>Erwerb von Werknutzungsbewilligungen</li>
                <li>Mitwirkung bei der Inbetriebnahme (Umstellungsunterstützung)</li>
                <li>Telefonische Beratung</li>
                <li>Programmwartung</li>
                <li>Erstellung von Programmträgern</li>
                <li>Sonstige Dienstleistungen</li>
              </ul>
            </li>
            <li>
              Die Ausarbeitung individueller Organisationskonzepte und Programme erfolgt nach Art und Umfang der vom Auftraggeber vollständig zur Verfügung gestellten bindenden Informationen, Unterlagen und Hilfsmittel. Dazu zählen auch praxisgerechte Testdaten sowie Testmöglichkeiten in ausreichendem Ausmaß, die der Auftraggeber zeitgerecht, in der Normalarbeitszeit und auf seine Kosten zur Verfügung stellt. Wird vom Auftraggeber bereits auf der zum Test zur Verfügung gestellten Anlage im Echtbetrieb gearbeitet, liegt die Verantwortung für die Sicherung der Echtdaten beim Auftraggeber.
            </li>
            <li>
              Grundlage für die Erstellung von Individualprogrammen ist die schriftliche Leistungsbeschreibung, die der Auftragnehmer gegen Kostenberechnung aufgrund der ihm zur Verfügung gestellten Unterlagen und Informationen ausarbeitet bzw. der Auftraggeber zur Verfügung stellt. Diese Leistungsbeschreibung ist vom Auftraggeber auf Richtigkeit und Vollständigkeit zu überprüfen und mit seinem Zustimmungsvermerk zu versehen. Später auftretende Änderungswünsche können zu gesonderten Termin- und Preisvereinbarungen führen.
            </li>
            <li>
              Individuell erstellte Software bzw. Programmadaptierungen bedürfen für das jeweils betroffene Programmpaket einer Programmabnahme spätestens vier Wochen ab Lieferung durch den Auftraggeber. Diese wird in einem Protokoll vom Auftraggeber bestätigt. (Prüfung auf Richtigkeit und Vollständigkeit anhand der vom Auftragnehmer akzeptierten Leistungsbeschreibung mittels der unter Punkt 2.2. angeführten zur Verfügung gestellten Testdaten). Lässt der Auftraggeber den Zeitraum von vier Wochen ohne Programmabnahme verstreichen, so gilt die gelieferte Software mit dem Enddatum des genannten Zeitraumes als abgenommen. Bei Einsatz der Software im Echtbetrieb durch den Auf¬traggeber gilt die Software jedenfalls als abgenommen.<br />
              
              Etwa auftretende Mängel, das sind Abweichungen von der schriftlich vereinbarten Leistungsbeschreibung, sind vom Auftraggeber ausreichend dokumentiert dem Auftragnehmer zu melden, der um rasche mögliche Mängelbehebung bemüht ist. Liegen schriftlich gemeldete, wesentliche Mängel vor, das heißt, dass der Echtbetrieb nicht begonnen oder fortgesetzt werden kann, so ist nach Mängelbehebung eine neuerliche Abnahme erforderlich.<br />
              Der Auftraggeber ist nicht berechtigt, die Abnahme von Software wegen unwesentlicher Mängel abzulehnen.
            </li>
            <li>
              Bei Bestellung von Bibliotheks-(Standard-)Programmen bestätigt der Auftraggeber mit der Bestellung die Kenntnis des Leistungsumfanges der bestellten Programme.
            </li>
            <li>
              Sollte sich im Zuge der Arbeiten herausstellen, dass die Ausführung des Auftrages gemäß Leistungsbeschreibung tatsächlich oder juristisch unmöglich ist, ist der Auftragnehmer verpflichtet, dies dem Auftraggeber sofort anzuzeigen. Ändert der Auftraggeber die Leistungsbeschreibung nicht dahingehend bzw. schafft die Voraussetzung, dass eine Ausführung möglich wird, kann der Auftragnehmer die Ausführung ablehnen. Ist die Unmöglichkeit der Ausführung die Folge eines Versäumnisses des Auftraggebers oder einer nachträglichen Änderung der Leistungsbeschreibung durch den Auftraggeber, ist der Auftragnehmer berechtigt, vom Auftrag zurückzutreten. Die bis dahin für die Tätigkeit des Auftragnehmers angefallenen Kosten und Spesen sowie allfällige Abbaukosten sind vom Auftraggeber zu ersetzen.
            </li>
            <li>
              Ein Versand von Programmträgern, Dokumentationen und Leistungsbeschreibungen erfolgt auf Kosten und Gefahr des Auftraggebers. Darüber hinaus vom Auftraggeber gewünschte Schulung und Erklärungen werden gesondert in Rechnung gestellt. Versicherungen erfolgen nur auf Wunsch des Auftraggebers.
            </li>
            <li>
              Ausdrücklich weisen wir daraufhin, dass eine barrierefreie Ausgestaltung (von Websites) iSd Bundesgesetzes über die Gleichstellung von Menschen mit Behinderungen (Bundes-Behindertengleichstellungsgesetz – BGStG)“ nicht im Angebot enthalten ist, sofern diese nicht gesondert/ individuell vom Auftraggeber angefordert wurde. Sollte die barrierefreie Ausgestaltung nicht vereinbart worden sein, so obliegt dem Auftraggeber die Überprüfung der Leistung auf ihre Zulässigkeit im Hinblick auf das Bundes-Behindertengleichstellungsgesetz durchzuführen. Ebenso hat der Auftraggeber von ihm bereit gestellte Inhalte auf ihre rechtliche, insbesondere wettbewerbs-, marken-, urheber- und verwaltungsrechtliche Zulässigkeit zu überprüfen. Der Auftragnehmer haftet im Falle leichter Fahrlässigkeit oder nach Erfüllung einer allfälligen Warnpflicht gegenüber dem Kunden nicht für die rechtliche Zulässigkeit von Inhalten, wenn diese vom Kunden vorgegeben wurden.
            </li>
          </ol>
        </li>
        <li>
          <strong>Preise, Steuern und Gebühren</strong><br />
          
          <ol>
            <li>Alle Preise verstehen sich in Euro ohne Umsatzsteuer. Sie gelten nur für den vorliegenden Auftrag. Die genannten Preise verstehen sich ab Geschäftssitz bzw. -stelle des Auftragnehmers. Die Kosten von Programmträgern (z.B. CD’s, Magnetbänder, Magnetplatten, Floppy Disks, Streamer Tapes, Magnetbandkassetten usw.) sowie allfällige Vertragsgebühren werden gesondert in Rechnung gestellt.</li>
            <li>Bei Bibliotheks- (Standard)-Programmen gelten die am Tag der Lieferung gültigen Listenpreise. Bei allen anderen Dienstleistungen (Organisationsberatung, Programmierung, Einschulung, Umstellungsunterstützung, telefonische Beratung usw.) wird der Arbeitsaufwand zu den am Tag der Leistungserbringung gültigen Sätzen verrechnet. Abweichungen von einem dem Vertragspreis zugrundeliegenden Zeitaufwand, der nicht vom Auftragnehmer zu vertreten ist, wird nach tatsächlichem Anfall berechnet.</li>
            <li>Die Kosten für Fahrt-, Tag- und Nächtigungsgelder werden dem Auftraggeber gesondert nach den jeweils gültigen Sätzen in Rechnung gestellt. Wegzeiten gelten als Arbeitszeit.</li>
          </ol>
        </li>
        <li>
          <strong>Liefertermin</strong><br />
          <ol>
            <li>Der Auftragnehmer ist bestrebt, die vereinbarten Termine der Erfüllung (Fertigstellung) möglichst genau einzuhalten.</li>
            <li>
              Die angestrebten Erfüllungstermine können nur dann eingehalten werden, wenn der Auftraggeber zu den vom Auftragnehmer angegebenen Terminen alle notwendigen Arbeiten und Unterlagen vollständig, insbesondere die von ihm akzeptierte Leistungsbeschreibung lt. Punkt 2.3. zur Verfügung stellt und seiner Mitwirkungsverpflichtung im erforderlichen Ausmaß nachkommt.<br />
              Lieferverzögerungen und Kostenerhöhungen, die durch unrichtige, unvollständige oder nachträglich geänderte Angaben und Informationen bzw. zur Verfügung gestellte Unterlagen entstehen, sind vom Auftragnehmer nicht zu vertreten und können nicht zum Verzug des Auftragnehmers führen. Daraus resultierende Mehrkosten trägt der Auftraggeber.
            </li>
            <li>Bei Aufträgen, die mehrere Einheiten bzw. Programme umfassen, ist der Auftragnehmer berechtigt, Teillieferungen durchzuführen bzw. Teilrechnungen zu legen.</li>
          </ol>
        </li>
        <li>
          <strong>Zahlung</strong><br />
          <ol>
            <li>Die vom Auftragnehmer gelegten Rechnungen inklusive Umsatzsteuer sind spätestens 14 Tage ab Fakturenerhalt ohne jeden Abzug und spesenfrei zahlbar. Für Teilrechnungen gelten die für den Gesamtauftrag festgelegten Zahlungsbedingungen analog.</li>
            <li>Bei Aufträgen, die mehrere Einheiten (z.B. Programme und/oder Schulungen, Reali¬sierungen in Teilschritten) umfassen, ist der Auftragnehmer berechtigt, nach Lieferung jeder einzelnen Einheit oder Leistung Rechnung zu legen.</li>
            <li>
              Die Einhaltung der vereinbarten Zahlungstermine bildet eine wesentliche Bedingung für die Durchführung der Lieferung bzw. Vertragserfüllung durch den Auftragnehmer. Die Nichteinhaltung der vereinbarten Zahlungen berechtigen den Auftragnehmer, die laufenden Arbeiten einzustellen und vom Vertrag zurückzutreten. Alle damit verbundenen Kosten sowie der Gewinnentgang sind vom Auftraggeber zu tragen.<br />
              Bei Zahlungsverzug werden Verzugszinsen im banküblichen Ausmaß verrechnet. Bei Nichteinhaltung zweier Raten bei Teilzahlungen ist der Auftragnehmer berechtigt, Terminverlust in Kraft treten zu lassen und übergebene Akzepte fällig zu stellen.
            </li>
            <li>Der Auftraggeber ist nicht berechtigt, Zahlungen wegen nicht vollständiger Gesamtlieferung, Garantie- oder Gewährleistungsansprüchen oder Bemängelungen zurück zu halten.</li>
          </ol>
        </li>
        <li>
          <strong>Urheberrecht und Nutzung</strong>
          <ol>
            <li>
              Der Auftragnehmer erteilt dem Auftraggeber nach Bezahlung des vereinbarten Entgelts ein nicht ausschließliches, nicht übertragbares, nicht unterlizenzierbares und zeitlich unbegrenztes Recht die Software für die im Vertrag spezifizierte Hardware und im Ausmaß der erworbenen Anzahl Lizenzen für die gleichzeitige Nutzung auf mehreren Arbeitsplätzen zu verwenden, sämtliche auf der Grundlage des Vertrages des Auftragnehmers erstellten Arbeitsergebnisse zum eigenen, internen Gebrauch zu nutzen. Sämtliche sonstige Rechte verbleiben beim Auftragnehmer.
              Durch die Mitwirkung des Auftraggebers bei der Herstellung der Software werden keine Rechte über die im gegenständlichen Vertrag festgelegte Nutzung erworben. Jede Verletzung der Urheberrechte des Auftragnehmers zieht Schadenersatzansprüche nach sich, wobei in einem solchen Fall volle Genugtuung zu leisten ist.
            </li>
            <li>Die Anfertigung von Kopien für Archiv- und Datensicherungszwecke ist dem Auftraggeber unter der Bedingung gestattet, dass in der Software kein ausdrückliches Verbot des Lizenzgebers oder Dritter enthalten ist, und dass sämtliche Copyright- und Eigentumsvermerke in diese Kopien unverändert mit übertragen werden.</li>
            <li>Sollte für die Herstellung von Interoperabilität der gegenständlichen Software die Offenlegung der Schnittstellen erforderlich sein, ist dies vom Auftraggeber gegen Kostenvergütung beim Auftragnehmer zu beauftragen. Kommt der Auftragnehmer dieser Forderung nicht nach und erfolgt eine Dekompilierung gemäß Urheberrechtsgesetz, sind die Ergebnisse ausschließlich zur Herstellung der Interoperabilität zu verwenden. Missbrauch hat Schadenersatz zur Folge.</li>
            <li>Wird dem Auftraggeber eine Software zur Verfügung gestellt, deren Lizenzinhaber ein Dritter ist (zB Standardsoftware von Microsoft), so richtet sich die Einräumung des Nutzungsrechts nach den Lizenzbestimmungen des Lizenzinhabers (Hersteller).</li>
          </ol>
        </li>
        <li>
          <strong>Rücktrittsrecht</strong><br />
          <ol>
            <li>Für den Fall der Überschreitung einer vereinbarten Lieferzeit aus alleinigem Verschulden oder rechtswidrigem Handeln des Auftragnehmers ist der Auftraggeber berechtigt, mittels eingeschriebenen Briefes vom betreffenden Auftrag zurückzutreten, wenn auch innerhalb der angemessenen Nachfrist die vereinbarte Leistung in wesentlichen Teilen nicht erbracht wird und den Auftraggeber daran kein Verschulden trifft.</li>
            <li>Höhere Gewalt, Arbeitskonflikte, Naturkatastrophen und Transportsperren sowie sonstige Umstände, die außerhalb der Einflussmöglichkeit des Auftragnehmers liegen, entbinden den Auftragnehmer von der Lieferverpflichtung bzw. gestatten ihm eine Neufestsetzung der vereinbarten Lieferzeit.</li>
            <li>Stornierungen durch den Auftraggeber sind nur mit schriftlicher Zustimmung des Auftragnehmers möglich. Ist der Auftragnehmer mit einem Storno einverstanden, so hat er das Recht, neben den erbrachten Leistungen und aufgelaufenen Kosten eine Stornogebühr in der Höhe von 30% des noch nicht abgerechneten Auftragswertes des Gesamtprojektes zu verrechnen.</li>
          </ol>
        </li>
        <li>
          <strong>Gewährleistung, Wartung, Änderungen</strong><br />
          <ol>
            <li>
              Der Auftragnehmer gewährleistet, dass die Software die in der dazugehörigen Dokumentation beschriebenen Funktionen erfüllt, sofern die Software auf dem im Vertrag beschriebenen Betriebssystem genutzt wird.
            </li>
            <li>
              <ol>
                <li>
                  Voraussetzung für die Fehlerbeseitigung ist, dass
                  <ul>
                    <li>der Auftraggeber den Fehler ausreichend in einer Fehlermeldung beschreibt und diese für den Auftragnehmer bestimmbar ist;</li>
                    <li>der Auftraggeber dem Auftragnehmer alle für die Fehlerbeseitigung erforderlichen Unterlagen zur Verfügung stellt;</li>
                    <li>der Auftraggeber oder ein ihm zurechenbarer Dritter keine Eingriffe in die Software vorgenommen hat;</li>
                    <li>die Software unter den Bestimmungsmäßigen Betriebsbedingungen entsprechend der Dokumentation betrieben wird.</li>
                  </ul>
                </li>
                <li>
                  Im Falle der Gewährleistung hat Verbesserung jedenfalls Vorrang vor Preisminderung oder Wandlung. Bei gerechtfertigter Mängelrüge werden die Mängel in angemessener Frist behoben, wobei der Auftraggeber dem Auftragnehmer alle zur Untersuchung und Mängelbehebung erforderlichen Maßnahmen ermöglicht.
                  <br /><br />
                  Die Vermutung der Mangelhaftigkeit gem. § 924 ABGB gilt als ausgeschlossen.
                </li>
                <li>
                  Korrekturen und Ergänzungen, die sich bis zur Übergabe der vereinbarten Leistung aufgrund organisatorischer und programmtechnischer Mängel, welche vom Auftragnehmer zu vertreten sind, als notwendig erweisen, werden kostenlos vom Auftragnehmer durchgeführt.
                </li>
              </ol>
            </li>
            <li>
              Kosten für Hilfestellung, Fehldiagnose sowie Fehler- und Störungsbeseitigung, die vom Auftraggeber zu vertreten sind sowie sonstige Korrekturen, Änderungen und Ergänzungen werden vom Auftragnehmer gegen Berechnung durchgeführt. Dies gilt auch für die Behebung von Mängeln, wenn Programmänderungen, Ergänzungen oder sonstige Eingriffe vom Auftraggeber selbst oder von dritter Seite vorgenommen worden sind.
            </li>
            <li>
              Ferner übernimmt der Auftragnehmer keine Gewähr für Fehler, Störungen oder Schäden, die auf unsachgemäße Bedienung, geänderter Betriebssystemkomponenten, Schnittstellen und Parameter, Verwendung ungeeigneter Organisationsmittel und Datenträger, soweit solche vorgeschrieben sind, anormale Betriebsbedingungen (insbesondere Abweichungen von den Installations- und Lagerbedingungen) sowie auf Transportschäden zurückzuführen sind.
            </li>
            <li>
              Für Programme, die durch eigene Programmierer des Auftraggebers bzw. Dritte nachträglich verändert werden, entfällt jegliche Gewährleistung durch den Auftragnehmer.
            </li>
            <li>
              Soweit Gegenstand des Auftrages die Änderung oder Ergänzung bereits bestehender Programme ist, bezieht sich die Gewährleistung auf die Änderung oder Ergänzung. Die Gewährleistung für das ursprüngliche Programm lebt dadurch nicht wieder auf.
            </li>
            <li>
              Gewährleistungsansprüche verjähren in sechs (6) Monaten ab Übergabe.
            </li>
          </ol>
        </li>
        <li>
          <strong>Haftung</strong><br />
          <ol>
            <li>
              Der Auftragnehmer haftet dem Auftraggeber für von ihm nachweislich verschuldete Schäden nur im Falle groben Verschuldens. Dies gilt sinngemäß auch für Schäden, die auf vom Auftragnehmer beigezogene Dritte zurückzuführen sind. Im Falle von verschuldeten Personenschäden haftet der Auftragnehmer unbeschränkt.
            </li>
            <li>
              Die Haftung für mittelbare Schäden - wie beispielsweise entgangenen Gewinn, Kosten die mit einer Betriebsunterbrechung verbunden sind, Datenverluste oder Ansprüche Dritter - wird ausdrücklich ausgeschlossen.
            </li>
            <li>
              Schadensersatzansprüche verjähren nach den gesetzlichen Vorschriften, jedoch spätestens mit Ablauf eines Jahres ab Kenntnis des Schadens und des Schädigers.
            </li>
            <li>
              Sofern der Auftragnehmer das Werk unter Zuhilfenahme Dritter erbringt und in diesem Zusammenhang Gewährleistungs- und/oder Haftungsansprüche gegenüber diesen Dritten entstehen, tritt der Auftragnehmer diese Ansprüche an den Auftraggeber ab. Der Auftraggeber wird sich in diesem Fall vorrangig an diese Dritten halten.
            </li>
            <li>
              Ist die Datensicherung ausdrücklich als Leistung vereinbart, so ist die Haftung für den Verlust von Daten abweichend von Punkt 9.2 nicht ausgeschlossen, jedoch für die Wiederherstellung der Daten begrenzt bis maximal EUR 10 % der Auftragssumme je Schadensfall, maximal jedoch EUR 15.000,-. Weitergehende als die in diesem Vertrag genannten Gewährleistungs- und Schadenersatzansprüche des AG -gleich aus welchem Rechtsgrund- sind ausgeschlossen.
            </li>
          </ol>
        </li>
        <li>
          <strong>Loyalität</strong><br />
          <ol>
            <li>
              Die Vertragspartner verpflichten sich zur gegenseitigen Loyalität. Sie werden jede Abwerbung und Beschäftigung, auch über Dritte, von Mitarbeitern, die an der Realisierung der Aufträge gearbeitet haben, des anderen Vertragspartners während der Dauer des Vertrages und 12 Monate nach Beendigung des Vertrages unterlassen. Der dagegen verstoßende Vertragspartner ist verpflichtet, pauschalierten Schadenersatz in der Höhe eines Jahresgehaltes des Mitarbeiters zu zahlen.
            </li>
          </ol>
        </li>
        <li>
          <strong>Geheimhaltung</strong>
          <ol>
            <li>
              Der Auftragnehmer verpflichtet seine Mitarbeiter, die Bestimmungen gemäß § 6 des Datenschutzgesetzes einzuhalten.
            </li>
          </ol>
        </li>
        <li>
          <strong>Sonstiges</strong>
          <ol>
            <li>
              Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder unwirksam werden, so wird hierdurch der übrige Inhalt dieses Vertrages nicht berührt. Die Vertragspartner werden partnerschaftlich zusammenwirken, um eine Regelung zu finden, die den unwirksamen Bestimmungen möglichst nahekommt.
            </li>
          </ol>
        </li>
        <li>
          <strong>Schlussbestimmungen</strong>
          <ol>
            <li>
              Soweit nicht anders vereinbart, gelten die zwischen Unternehmern zur Anwendung kommenden gesetzlichen Bestimmungen ausschließlich nach österreichischem Recht, auch dann, wenn der Auftrag im Ausland durchgeführt wird. Für eventuelle Streitigkeiten gilt ausschließlich die örtliche Zuständigkeit des sachlich zuständigen Gerichtes für den Geschäftssitz des Auftragnehmers als vereinbart. Für den Verkauf an Verbraucher im Sinne des Konsumentenschutzgesetzes gelten die vorstehenden Bestimmungen nur insoweit, als das Konsumentenschutzgesetz nicht zwingend andere Bestimmungen vorsieht.
            </li>
          </ol>
        </li>
      </ol>

      <p>
        Der Fachverband Unternehmensberatung und Informationstechnologie empfiehlt als
        wirtschaftsfreundliches Mittel der Streitschlichtung nachfolgende Mediationsklausel:
      </p>

      <p>
        Für den Fall von Streitigkeiten aus diesem Vertrag, die nicht einvernehmlich geregelt
        werden können, vereinbaren die Vertragsparteien einvernehmlich zur außergerichtlichen
        Beilegung des Konfliktes eingetragene Mediatoren (ZivMediatG) mit dem Schwerpunkt
        WirtschaftsMediation aus der Liste des Justizministeriums beizuziehen. Sollte über die
        Auswahl der WirtschaftsMediatoren oder inhaltlich kein Einvernehmen hergestellt werden
        können, werden frühestens ein Monat ab Scheitern der Verhandlungen rechtliche Schritte
        eingeleitet.
      </p>

      <p>
        Im Falle einer nicht zustande gekommenen oder abgebrochenen Mediation, gilt in einem
        allfällig eingeleiteten Gerichtsverfahren österreichisches Recht.
        Sämtliche aufgrund einer vorherigen Mediation angelaufenen notwendigen Aufwendungen,
        insbesondere auch jene für eine(n) beigezogene(n) RechtsberaterIn, können
        vereinbarungsgemäß in einem Gerichts- oder Schiedsgerichtsverfahren als „vorprozessuale
        Kosten“ geltend gemacht werden.
      </p>

    </section>
  </Layout>
)

export default IndexPage